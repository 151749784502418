body {
  font-family: Montserrat;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

#_mdspbar {
  z-index: 1040 !important;
  line-height: 9px;
}

#_mdspcontent {
  overflow: hidden;
}

#root {
  height: 100%;
  min-height: 100%;
}

.modal {
  z-index: 10050 !important;
}

@media (max-width: 991.92px) {
  #_mdspbar {
    display: none !important;
  }
}
